import {CustomerStatus} from "../types/customer";

export const  getShowCustomerStatus  = (customerStatus: CustomerStatus) => {
    switch (customerStatus) {
        case CustomerStatus.PROCESSING:
            return "Ожидание...";
        case CustomerStatus.AVAILABLE:
            return "Открытый";
        case CustomerStatus.NOT_AVAILABLE:
            return "Недоступен";
        default:
            return "Ожидание...";
    }
}