import React from "react";
import {ReactComponent as EmblemIcon} from "../../../assets/emblem.svg";
import api from "../../../api/baseApi";
import {useAppDispatch} from "../../../hooks/reduxHooks";
import {login} from "../../../store/slices/auth-slice";
import {useNavigate} from "react-router-dom";
import {Tokens} from "../../../common/types/token";
import {setTokens} from "../../../common/util/tokens";

export default function AdminLogin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [formData, setFormData] = React.useState({
        nickname: "",
        password: "",
    });

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const onFormSubmit = async () => {
        try {
            const response = await api<Tokens>(
                `/auth/admin/sign-in`,
                {
                    method: "POST",
                    data: formData,
                }
            );
            if (response?.refresh_token && response?.access_token) {
                dispatch(login());
                setTokens(response);
                navigate("/customers");
            }
        } catch (error) {
            console.error("Error during form submission", error);
        }
    };

    return (
        <div className=" flex items-center justify-center">
            <div className="p-5 flex flex-col bg-white shadow rounded-2xl">
                <div className="text-center mb-3">
                    <h1 className="text-xl font-semibold text-gray-800">
                        Enter your credentials to sign in.
                    </h1>
                </div>
                <div className="flex justify-center mb-3">
                    <EmblemIcon/>
                </div>

                <form>
                    <div className="space-y-5">
                        <div className="space-y-3">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-800">
                                    Nickname
                                </label>
                                <input
                                    id="nickname"
                                    name="nickname"
                                    type="text"
                                    onChange={onFormChange}
                                    value={formData.nickname}
                                    className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                    placeholder="Nickname"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-800">
                                    Password
                                </label>

                                <div className="relative">
                                    <input
                                        id="hs-pro-dalp"
                                        name="password"
                                        onChange={onFormChange}
                                        value={formData.password}
                                        type={showPassword ? "text" : "password"}
                                        className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                        placeholder="********"
                                    />
                                    <button
                                        onClick={() => setShowPassword(!showPassword)}
                                        type="button"
                                        data-hs-toggle-password='{
                          "target": "#hs-pro-dalp"
                        }'
                                        className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600"
                                    >
                                        <svg
                                            className="flex-shrink-0 size-3.5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                className="hs-active:hidden"
                                                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                                            />
                                            <path
                                                className="hs-active:hidden"
                                                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                                            />
                                            <path
                                                className="hs-active:hidden"
                                                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                                            />
                                            <path
                                                className="hidden hs-active:block"
                                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                            />
                                            <path
                                                className="hidden hs-active:block"
                                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <button
                                type="button"
                                onClick={onFormSubmit}
                                className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
