import React, {FC} from 'react';
import {CgSpinnerAlt} from "react-icons/cg";

interface ILoadingButtonProps {
    className?: string;
}

const LoadingButton: FC<ILoadingButtonProps> = ({className}) => {
    return (
        <div className={` flex h-[54px] mt-6 max-w-[360px] items-center justify-center ${className}`}>
            <CgSpinnerAlt size={25}  className="animate-spin text-blue-500" />
        </div>
    );
};

export default LoadingButton;