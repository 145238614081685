import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {IDialog} from "../../common/types/dialog";

export interface DialogsState {
  dialogs: IDialog[];
  currentDialog?: IDialog | null;
}

const initialState: DialogsState = {
  dialogs: [],
  currentDialog: null,
};

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    setDialogs: (state, action: PayloadAction<IDialog[]>) => {
      state.dialogs = action.payload;
    },
    setCurrentDialog: (state, action: PayloadAction<IDialog>) => {
      state.currentDialog = action.payload;
    },
  },
});

export const { setDialogs, setCurrentDialog } = dialogsSlice.actions;

export const DialogReducer = dialogsSlice.reducer;
