import React, {useEffect, useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import MessagesHeader from "./dialog-components/MessagesHeader";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import api from "../../../api/baseApi";
import {setMessages} from "../../../store/slices/messages-slice";
import DialogContent from "./dialog-components/DialogContent";
import {setCurrentDialog} from "../../../store/slices/dialogs-slice";
import {IDialog} from "../../../common/types/dialog";

const DialogPage = () => {
    const {dialogId} = useParams();
    const dispatch = useAppDispatch();

    const currentCustomer = useAppSelector((state) => state.customer.currentCustomer) as ICustomer;
    const {tgId} = currentCustomer;
    const dialog = useAppSelector((state) => state.dialogs.currentDialog);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (dialog && dialog.id === dialogId) {
            setIsLoading(false);
            return;
        }
        dispatch(setMessages({messages: [], total: 0}));
        fetchDialog().then(() => {
            setIsLoading(false);
        });
    }, []);

    const fetchDialog = async () => {
        const response = await api<IDialog>(`/customers/dialogs/${dialogId}`, {
            method: 'get',
        });

        dispatch(setCurrentDialog(response));
    }


    if (isLoading) {
        return <div>Loading ....</div>
    }

    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={'p-[30px]'}>
                <div className={'flex-col flex w-full'}>
                    <MessagesHeader dialog={dialog as IDialog} customerId={tgId}/>
                    <DialogContent>
                        <Outlet/>
                    </DialogContent>
                </div>
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default DialogPage;