import React, { useRef, useEffect, useState, useCallback } from "react";
import { IMessage } from "../../../../common/types/message";
import MessageContainer from "../messages-components/MessageContainer";
import Message from "../messages-components/Message";
import { MyPagination } from "../../../../common/types/plagination";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { ICustomer } from "../../../../common/types/customer";
import api from "../../../../api/baseApi";
import { setMessages } from "../../../../store/slices/messages-slice";
import { IDialog } from "../../../../common/types/dialog";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { ITelegramUser } from "../../../../common/types/telegram-user";
import { useParams } from "react-router-dom";

const MessagesWindow = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages.messages);
  const dialog: IDialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const customerId = customer.tgId;
  const [messagesPagination, setMessagesPagination] = useState<MyPagination>({
    page: 0,
    limit: 50,
    skip: 0,
  });

  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMessages = async (page?: number) => {
    try {
      const messagesData: any = await api(
        `/customers/messages?dialogId=${
          dialog.tgId
        }&customerId=${customerId}&page=${
          page ? page : messagesPagination.page
        }&limit=${messagesPagination.limit}`,
        {
          headers: {
            credentials: "include",
          },
        }
      );
      const fetchedMessages = messagesData.messages as IMessage[];

      if (isLoading) {
        setIsLoading(false);
      }

      const allMessages = [...(messages || []), ...fetchedMessages];

      const uniqueMessages = Array.from(
        new Map(allMessages.map((msg) => [msg.tgId, msg])).values()
      ).sort((a, b) => parseInt(b.tgId) - parseInt(a.tgId));

      dispatch(
        setMessages({
          messages: uniqueMessages,
          total: messagesData.total,
        })
      );
    } catch (error) {
      console.log("Error fetching dialogs", error);
    }
  };

  const checkScrollTop = async () => {
    if (!messagesWindowRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messagesWindowRef.current;
    if (Math.abs(scrollTop) + clientHeight === scrollHeight) {
      setMessagesPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      if (messagesPagination.page === 0) {
        await fetchMessages(1);
      } else {
        await fetchMessages();
      }
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    const divRef = messagesWindowRef.current;
    if (divRef) {
      divRef.addEventListener("scroll", checkScrollTop);
    }
    return () => {
      if (divRef) {
        divRef.removeEventListener("scroll", checkScrollTop);
      }
    };
  }, [checkScrollTop]);

  if (isLoading) {
    return <div>Загрузка ...</div>;
  }

  if (!messages) {
    return <div>Сообщения отсутствуют</div>;
  }

  return (
    <div
      ref={messagesWindowRef}
      className={"flex flex-col-reverse gap-y-2 max-h-[600px] overflow-auto"}
    >
      {messages.map((message) => {
        const sender: ITelegramUser = dialog.users?.find(
          (user) => user.tgId === message.senderId
        ) as ITelegramUser;
        return (
          <MessageContainer
            customerId={customerId}
            senderId={message.senderId || ""}
          >
            <Message message={message} sender={sender} />
          </MessageContainer>
        );
      })}
    </div>
  );
};
export default MessagesWindow;
