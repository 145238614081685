import {FC, ReactNode} from 'react';
import {Link} from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

interface CustomerInfoButtonProps {
    icon: ReactNode;
    text: string;
    href: string;
    cb?: () => void;
}
const CustomerInfoButton: FC<CustomerInfoButtonProps> = ({icon, text, href, cb}) => {
    return (
        <Link to={href} onClick={cb}
        className={'bg-[#141515] border border-[#252627] w-[264px] text-[#d7d7d7] transition-colors items-center rounded-md hover:bg-[#3d3e3f] px-[23px] py-3 flex justify-between'}>
            <div className={'flex gap-x-2 items-center'}>
                {icon}
                <p className={'font-bold text-lg'}>
                    {text}
                </p>
            </div>
            <FaAngleRight size={15} />
        </Link>
    );
};

export default CustomerInfoButton;