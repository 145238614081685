import React from "react";
import { FiRepeat } from "react-icons/fi";
import api from "../../api/baseApi";

interface ReparseButtonProps {
  customerId: string;
}

const ReparseButton: React.FC<ReparseButtonProps> = ({ customerId }) => {
  const reparseData = async () => {
    try {
      const response = await api(`/reparser/${customerId}`, {
        method: "POST",
      });

      if (response) {
        console.log("Reparse successful:", response);
      }
    } catch (error) {
      console.error("Error reparsing data:", error);
    }
  };

  return (
    <button
      onClick={reparseData}
      title="Повторный запуск парсинга"
      className="flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg bg-[#1e3a8a] hover:bg-[#1d4ed8] border border-blue-700 z-30 relative"
    >
      <FiRepeat size={18} />
    </button>
  );
};

export default ReparseButton;
