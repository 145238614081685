import React, { useEffect } from "react";
import api from "../../../api/baseApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setCustomer } from "../../../store/slices/customer-slice";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import { ICustomer } from "../../../common/types/customer";
import CustomersList from "./CustomersList";

export default function CustomersPage() {
  const dispatch = useAppDispatch();
  const customersData = useAppSelector((state) => state.customer.customers);

  const fetchCustomers = async () => {
    try {
      const customers: ICustomer[] = await api(`/customers`, {
        headers: {
          credentials: "include",
        },
      });
      if (customers) {
        dispatch(setCustomer(customers));
      }
    } catch (error) {
      console.log("Error fetching customers", error);
    }
  };

  const deleteCustomer = async (customerId: string) => {
    try {
      await api(`customers/dialog/${customerId}`, {
        method: "DELETE",
        headers: {
          credentials: "include",
        },
      });
      dispatch(
        setCustomer(
          customersData.filter((customer) => customer.tgId !== customerId)
        )
      );
    } catch (error) {
      console.log("Error deleting customer", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    //eslint-disable-next-line
  }, []);

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <CustomersList customers={customersData} onDelete={deleteCustomer} /> {}
      </CustomersInfoCard>
    </ContentContainer>
  );
}
