import React from "react";
import { LuRefreshCw } from "react-icons/lu";
import api from "../../api/baseApi";

interface RefreshChatButtonProps {
  customerId: string;
  dialogId: string;
}

const RefreshChatButton: React.FC<RefreshChatButtonProps> = ({
  customerId,
  dialogId,
}) => {
  const refreshChat = async () => {
    try {
      const response = await api("customers/refresh-dialog", {
        method: "PUT",
        data: {
          customerId,
          dialogId,
        },
      });

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error("Error refreshing chat:", error);
    }
  };

  return (
    <button
      onClick={refreshChat}
      title="Обновить чат"
      className="flex gap-x-2 items-center text-white p-2 transition-colors rounded-lg bg-[#2d2e2f] hover:bg-[#1c1c1d]"
    >
      <LuRefreshCw size={23} />
    </button>
  );
};

export default RefreshChatButton;
