import {IMedia} from "../../common/types/media";
import UrlDownloadDocument from "./UrlDownloadDocument";
import BufferDownloadDocument from "./BufferDownloadDocument";

interface DocumentProps {
    media: IMedia;
}

export const Document = ({media}: DocumentProps) => {
    if ('url' in media) {
        return <UrlDownloadDocument url={media.url as string} mimeType={media.mediaType}/>
    } else {
        return <BufferDownloadDocument base64={media.media as string} mimeType={media.mediaType}/>
    }
}