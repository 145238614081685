import React, { FC } from "react";
import Avatar from "../../../../components/media-components/Avatar";
import { IDialog } from "../../../../common/types/dialog";
import BackArrowNavigate from "../../../../components/buttons/BackArrowNavigate";
import DialogNavButton from "./DialogNavButton";
import { AiFillPicture } from "react-icons/ai";
import { IoChatbubble } from "react-icons/io5";
import { FaVideo, FaFileAudio } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";
import api from "../../../../api/baseApi";

interface IMessagesHeaderProps {
  dialog: IDialog;
  customerId: string;
}

const MessagesHeader: FC<IMessagesHeaderProps> = ({ dialog, customerId }) => {
  return (
    <div className={"flex pb-5 justify-between items-center"}>
      <div className={"flex gap-x-5 "}>
        <Avatar
          url={dialog.avatarsUrl?.[0] || ""}
          customSize={"w-[50px] h-[50px]"}
          customIconSize={30}
        />
        <div className={"flex flex-col "}>
          <p className={"text-lg font-bold"}>{dialog.name} </p>
        </div>
      </div>
      <div className={"flex gap-x-3 items-center"}>
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}`}
          icon={<IoChatbubble size={25} />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/images`}
          icon={<AiFillPicture size={25} />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/videos`}
          icon={<FaVideo size={25} />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/documents`}
          icon={<IoIosDocument size={25} />}
        />
        <DialogNavButton
          href={`/customers/${customerId}/data/dialogs/${dialog.id}/audio`}
          icon={<FaFileAudio size={23} />}
        />
      </div>

      <BackArrowNavigate
        backUrl={`/customers/${customerId}/data/all-dialogs`}
      />
    </div>
  );
};

export default MessagesHeader;
