export const getDateFromUnixTime = (date: number): string => {
    const sentDate = new Date(date* 1000);
    const day = sentDate.getDate();
    const month = sentDate.getMonth() + 1;
    const year = sentDate.getFullYear();

    const hours = sentDate.getHours();
    const minutes = sentDate.getMinutes();

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
}