import React, {useEffect, useState} from 'react';
import {IContact, ITelegramUser} from "../../../common/types/telegram-user";
import api from "../../../api/baseApi";
import {useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import ContactItem from "./ContactItem";

const ContactsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const customer = useAppSelector((state) =>
        state.customer.currentCustomer) as ICustomer;

    const {tgId} = customer;

    useEffect(() => {
        const fetchContacts = async () => {
            const response = await api(`/customers/${tgId}/contacts`);
            setContacts(response as IContact[]);
            setIsLoading(false);
        }
        fetchContacts();
    }, []);


    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!isLoading && contacts.length === 0) {
        return <div>No contacts found</div>
    }

    return (
        <div className={'w-full flex flex-col gap-y-2 min-w-[800px]'}>
            {
                contacts.map((contact) => (
                    <ContactItem contact={contact} customerId={tgId} key={contact.tgId}/>
                ))
            }
        </div>
    );
};

export default ContactsPage;