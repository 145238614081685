import React from 'react';
import CustomersInfoCard from "../cards/CustomersInfoCard";
import ContentContainer from "./ContentContainer";
import CustomerInfoHeader from "./CustomerInfoHeader";
import CustomerInfoNavigation from "./CustomerInfoNavigation";
import {Outlet} from "react-router-dom";

const CustomerInfoLayout = () => {
    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={'p-[30px]'}>
                <div className={'flex flex-col'}>
                    <CustomerInfoHeader/>
                    <div className={'flex flex-col bg-[#191919] border-2 border-[#252627] rounded-md'}>
                        <div className={'flex'}>
                            <CustomerInfoNavigation/>
                            <div className={'p-[30px] bg-[#1c1d1d] border rounded-md border-[#252627]'}>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default CustomerInfoLayout;