import React, {FC, ReactNode} from 'react';

interface CustomersInfoCardProps {
    children: ReactNode;
    customPadding: string;
}

const CustomersInfoCard: FC<CustomersInfoCardProps> = ({children, customPadding }) => {

    return (
        <div className={`bg-[#161616]  text-[#d7d7d7]  rounded-md max-w-[1200px]  border-2 border-[#252627] w-full h-full flex ${customPadding}`}>
            {children}
        </div>
    );
};

export default CustomersInfoCard;