import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {ICustomer} from "../../common/types/customer";


export interface CustomerState {
  customers: ICustomer[];
  currentCustomer?: ICustomer | null;
}

const initialState: CustomerState = {
  customers: [],
  currentCustomer: null,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<ICustomer[]>) => {
      state.customers = action.payload;
    },
    setCurrentCustomer: (state, action: PayloadAction<ICustomer>) => {
      state.currentCustomer = action.payload;
    },
  },
});

export const selectCustomerByTgId = (tgId: string) => (state: any) => {
  return state.customer.customers.find((customer: ICustomer) => customer.tgId === tgId);
}

export const { setCustomer, setCurrentCustomer } = customerSlice.actions;

export const CustomersReducer = customerSlice.reducer;
