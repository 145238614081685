import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import api from "../../../api/baseApi";
import {setDialogs} from "../../../store/slices/dialogs-slice";
import {IDialog} from "../../../common/types/dialog";
import DialogListItem from "./DialogListItem";
// import Pagination from "../components/Pagination";

export default function AllDialogsPage() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const customersData = useAppSelector(
        (state) => state.customer.currentCustomer
    );
    const dialogs = useAppSelector((state) => state.dialogs.dialogs);
    const [queryParams, setQueryParams] = useState({
        page: 1,
        limit: 50,
        customerId: customersData?.tgId,
    });

    useEffect(() => {
        const fetchDialogs = async (queryParams: any) => {
            try {
                const dialogs: IDialog[] = await api(
                    `/customers/dialogs?customerId=${queryParams.customerId}&page=${queryParams.page}&limit=${queryParams.limit}`,
                    {
                        headers: {
                            credentials: "include",
                        },
                    }
                );

                dispatch(setDialogs(dialogs));
                setIsLoading(false)
            } catch (error) {
                console.log("Error fetching dialogs", error);
            }
        };
        fetchDialogs(queryParams);
    }, []);

    if (isLoading) {
        return <div>Загрузка...</div>
    }

    return (
        <div className={'flex flex-col gap-y-2 w-full min-w-[800px]'}>
            {
                dialogs.map(dialog => (
                    <DialogListItem dialog={dialog} key={dialog.tgId}/>
                ))
            }
        </div>
    );
}
