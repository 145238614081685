import React, {FC} from 'react';

interface BufferAudioProps {
    url: string;
    classes?: string;
}

const UrlAudio: FC<BufferAudioProps> = ({url, classes}) => {

    return (
        <audio controls className={classes}>
            <source src={url} type="audio/mp4"/>
            Your browser does not support the audio element.
        </audio>
    );
};

export default UrlAudio;