import {IMedia} from "../../common/types/media";
import {FC} from "react";
import UrlAudio from "./UrlAudio";
import BufferAudio from "./BufferAudio";

interface AudioProps {
    media: IMedia;
}

export const Audio: FC<AudioProps> = ({media}) => {
    if ('url' in media) {
        return (
            <UrlAudio url={media.url as string}/>
        )
    } else {
        return (
            <BufferAudio audioData={media.media as string}/>
        )
    }
}