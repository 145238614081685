import React from 'react';
import {useAppSelector} from "../../hooks/reduxHooks";
import Avatar from "../media-components/Avatar";
import {ICustomer} from "../../common/types/customer";


const CustomerInfoHeader = () => {
    const currentCustomer = useAppSelector((state) => {
        return state.customer.currentCustomer;
    }) as ICustomer;


    return (
      <div className={"pb-5 flex justify-between"}>
        <div className={"flex gap-x-5"}>
          <Avatar
            url={currentCustomer?.userAvatars?.[0] || ""}
            customIconSize={35}
            customSize={"w-[60px] h-[60px]"}
          />
          <div className={"flex flex-col"}>
            <p className={"font-bold text-lg"}>
              {currentCustomer?.firstName ?? ""}{" "}
              {currentCustomer?.lastName ?? ""}
            </p>
            <p>@{currentCustomer?.username}</p>
          </div>
        </div>
      </div>
    );
};

export default CustomerInfoHeader;