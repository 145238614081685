import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Bugsnag.start({
  apiKey: "ece14aee567eeffcdbc31ca1fb47dd7a",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "ece14aee567eeffcdbc31ca1fb47dd7a" });
//@ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

// window.onerror = function (message, source, lineno, colno, error) {
//   console.error("Global error caught:", message, source, lineno, colno, error);
//   return true;
// };

// window.onunhandledrejection = function (event) {
//   console.error("Unhandled promise rejection:", event.reason);
//   Bugsnag.notify(event.reason);
//   return true;
// };

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

reportWebVitals();
