import { useEffect, useState } from "react";
import pako from "pako";

const useTGSanimation = (url: string) => {
  const [jsonData, setJsonData] = useState("");

  useEffect(() => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const inflated = pako.inflate(new Uint8Array(data), { to: "string" });
        setJsonData(inflated);
      })
      .catch((error) => console.error(error));
  }, [url]);

  return jsonData;
};

export default useTGSanimation;
