import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {IMessage} from "../../common/types/message";

interface MessagesData {
  messages: IMessage[];
  total: number;
}

export interface MessagesState {
  messages: IMessage[];
  currentMessage?: IMessage | null;
  total: number;
}

const initialState: MessagesState = {
  messages: [],
  currentMessage: null,
  total: 0,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessagesData>) => {
      state.messages = action.payload.messages;
      state.total = action.payload.total;
    },
    setCurrentMessage: (state, action: PayloadAction<IMessage>) => {
      state.currentMessage = action.payload;
    },
  },
});

export const { setMessages, setCurrentMessage } = messagesSlice.actions;

export const MessagesReducer = messagesSlice.reducer;
