import React, { FC } from "react";
import { ICustomer } from "../../../common/types/customer";
import CustomerListItem from "./CustomerListItem";

interface CustomersListProps {
  customers: ICustomer[];
  onDelete: (customerId: string) => void;
}

const CustomersList: FC<CustomersListProps> = ({ customers, onDelete }) => {
  return (
    <ul className={"flex flex-col gap-y-2 w-full"}>
      {customers.map((customer) => (
        <CustomerListItem
          customer={customer}
          key={customer.tgId}
          onDelete={() => onDelete(customer.tgId)}
        />
      ))}
    </ul>
  );
};

export default CustomersList;
