import React, {FC, useEffect, useState} from 'react';
import {ICustomer, StaticStatus} from "../../../common/types/customer";
import api from "../../../api/baseApi";
import {useAppDispatch} from "../../../hooks/reduxHooks";
import {setCurrentCustomer} from "../../../store/slices/customer-slice";


interface DownloadButtonProps {
    customer: ICustomer;
}

const DownloadButton: FC<DownloadButtonProps> = ({customer}) => {
    const {tgId, staticStatus: status, staticAppUrl} = customer;
    const [buttonText, setButtonText] = useState('');
    const dispatch = useAppDispatch();

    async function handleClick() {
        if (customer.staticStatus !== StaticStatus.PROCESSING ) {
            await generate();
        }
    }


    useEffect(() => {
        if (!status) {
            setButtonText('Сгенерировать архив');
            return;
        }

        if (status === StaticStatus.PROCESSING) {
            setButtonText('Загрузка...');
        }
        else {
            setButtonText('Сгенерировать архив');
        }
    }, [customer.staticStatus]);

    async function generate() {
        try {
            await api(`/local-saver/generate?customerId=${tgId}`, {
                method: 'GET',
            })
            dispatch(setCurrentCustomer({...customer, staticStatus: StaticStatus.PROCESSING}));
        } catch (error) {
            console.log(error)
        }

    }



    return (
        <>
            <button onClick={() => {
                handleClick()
            }}
                    className={`bg-[#141515] border border-[#252627] max-w-[264px] text-[#d7d7d7] transition-colors items-center rounded-md  ${customer.staticStatus === StaticStatus.PROCESSING ? 'bg-slate-300 text-slate-700 cursor-default' : 'hover:bg-[#3d3e3f] cursor-pointer'}  px-[23px] py-3`}
            >
                {buttonText}
            </button>

            {
                customer.staticAppUrl &&
                <a href={customer.staticAppUrl} download={customer.firstName} rel="noreferrer"
                   className={`bg-[#141515] border border-[#252627] max-w-[264px] text-[#d7d7d7] transition-colors items-center rounded-md hover:bg-[#3d3e3f] px-[23px] py-3 text-center`}>
                    Скачать архив
                </a>
            }
        </>

    );
};

export default DownloadButton;