import React from 'react';
import SelectMenuItemButton from "./SelectMenuItemButton";
import {useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import {FaImage} from "react-icons/fa";
import {FaVideo} from "react-icons/fa";
import {IoIosDocument} from "react-icons/io";
import { FaFileAudio } from "react-icons/fa6";


const FileMenuPage = () => {
    const customer = useAppSelector(state => state.customer.currentCustomer) as ICustomer;
    const {tgId} = customer;
    return (
        <div className={'flex flex-col gap-y-2 w-full min-w-[800px]'}>
            <SelectMenuItemButton
                href={`/customers/${tgId}/data/files/photos`}
                title={'Фотографии'}
                subtitle={'Все'}
                icon={<FaImage size={25}/>
                }
            />
            <SelectMenuItemButton
                href={`/customers/${tgId}/data/files/videos`}
                title={'Видео'}
                subtitle={'Все'}
                icon={<FaVideo size={25}/>
                }
            />
            <SelectMenuItemButton
                href={`/customers/${tgId}/data/files/documents`}
                title={'Документы'}
                subtitle={'Все'}
                icon={<IoIosDocument size={25}/>
                }
            />
            <SelectMenuItemButton
                href={`/customers/${tgId}/data/files/audio`}
                title={'Аудиофайлы'}
                subtitle={'Все'}
                icon={<FaFileAudio size={25}/>
                }
            />
        </div>
    );
};

export default FileMenuPage;