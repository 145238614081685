import React, {FC} from 'react';

interface LinkFormInputProps {
    placeholder: string;
    value: string;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
    isValid?: boolean;
    name: string;
    onPaste?: (e: any) => void;
}

const LinkFormInput: FC<LinkFormInputProps> = ({placeholder, onChange, value, isValid, onPaste, onBlur, name}) => {
    return (
        <input
            className={`w-full mt-1  border border-[#252627]  transition-colors rounded-md  px-2 py-1 ${isValid ? 'bg-[#252627] text-[#d7d7d7]' : 'bg-rose-800 text-white'}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            name={name}
            onPaste={onPaste}
        />
    );
};

export default LinkFormInput;