import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaQuery } from "../../../../common/types/plagination";
import { IMedia, MediaType } from "../../../../common/types/media";
import api from "../../../../api/baseApi";
import UrlDownloadDocument from "../../../../components/media-components/UrlDownloadDocument";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { IDialog } from "../../../../common/types/dialog";
import { Document } from "../../../../components/media-components/Document";
import { ICustomer } from "../../../../common/types/customer";

const DialogDocuments = () => {
  const dialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const dialogId = dialog.tgId;
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 100,
    skip: 0,
    mediaType: MediaType.DOCUMENT,
    customerId: currentCustomer.tgId,
  });

  const [documents, setDocuments] = useState<IMedia[]>([]);

  const fetchDocuments = async (page: number = query.page) => {
    try {
      const url = `/customers/dialog-media/${dialogId}?mediaType=${query.mediaType}&page=${page}&limit=${query.limit}&customerId=${query.customerId}`;

      const result = await api(url, {
        method: "GET",
        headers: {
          credentials: "include",
        },
      });

      setDocuments((prevDocuments) =>
        page === 1
          ? (result as IMedia[])
          : [...prevDocuments, ...(result as IMedia[])]
      );
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching documents", error);
    }
  };

  const checkScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log("fetching more images");
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
      await fetchDocuments(query.page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [query.page]);

  useEffect(() => {
    fetchDocuments(1);
  }, []);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (documents.length === 0 && !isLoading) {
    return <div>Документы отсутствуют.</div>;
  }

  return (
    <div
      className={
        "flex flex-wrap gap-3 w-full min-h-screen  h-full justify-start pl-20"
      }
    >
      {documents.map((doc: IMedia) => (
        <Document media={doc} key={doc.id} />
      ))}
    </div>
  );
};

export default DialogDocuments;
