import React, { useEffect, useState } from "react";
import { IMedia, MediaType } from "../../../../common/types/media";
import { MediaQuery } from "../../../../common/types/plagination";
import api from "../../../../api/baseApi";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { IDialog } from "../../../../common/types/dialog";
import { Video } from "../../../../components/media-components/Video";
import { ICustomer } from "../../../../common/types/customer";

const DialogVideos = () => {
  const [videos, setVideos] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dialog = useAppSelector(
    (state) => state.dialogs.currentDialog
  ) as IDialog;
  const dialogId = dialog.tgId;
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 300,
    skip: 0,
    mediaType: MediaType.VIDEO,
    customerId: currentCustomer.tgId,
  });

  const fetchVideos = async (page: number = query.page) => {
    try {
      const url = `/customers/dialog-media/${dialogId}?mediaType=${query.mediaType}&page=${page}&limit=${query.limit}&customerId=${query.customerId}`;

      const result = await api(url, {
        method: "GET",
        headers: {
          credentials: "include",
        },
      });


      setVideos((prevVideos) =>
        page === 1
          ? (result as IMedia[])
          : [...prevVideos, ...(result as IMedia[])]
      );
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching images", error);
    }
  };

  const checkScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log("fetching more images");
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
      await fetchVideos(query.page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [query.page]);

  useEffect(() => {
    fetchVideos(query.page);
  }, []);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }
  if (videos.length === 0) {
    return <div>Видео отсутствуют.</div>;
  }

  return (
    <div
      className={
        "flex flex-wrap gap-3 w-full h-full min-h-screen justify-start"
      }
    >
      {videos.map((video, index) => (
        <Video media={video} key={index} />
      ))}
      {isLoading && <div>Загрузка...</div>}
    </div>
  );
};

export default DialogVideos;
