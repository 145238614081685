import React, {useCallback, useEffect, useState} from 'react';
import {IMedia, MediaType} from "../../../common/types/media";
import {useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import {MediaQuery} from "../../../common/types/plagination";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import UrlDownloadDocument from "../../../components/media-components/UrlDownloadDocument";
import {Document} from "../../../components/media-components/Document";

const CustomerDocumentsPage = () => {

    const [documents, setDocuments] = useState<IMedia[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const customer = useAppSelector(state => state.customer.currentCustomer) as ICustomer;
    const id = customer.tgId;

    const [query, setQuery] = useState<MediaQuery>({
        page: 1,
        limit: 150,
        skip: 0,
        mediaType: MediaType.DOCUMENT
    });

    const fetchDocuments = useCallback(async (page: number) => {
        console.log(page)
        try {
            const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${page === 1 ? 500 : query.limit}`;
            const result = await api(url, {
                method: 'GET',
                headers: {
                    credentials: "include",
                },
            });
            const newDocuments = result as IMedia[];
            setDocuments(prevImages => [...prevImages, ...newDocuments]);
        } catch (error) {
            console.log("Error fetching images", error);
        } finally {
            setIsLoading(false);
        }
    }, [id, query.limit]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 1) {
            setQuery(prev => ({...prev, page: prev.page + 1}));
        }
    }, [isLoading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);


    useEffect(() => {
        fetchDocuments(query.page);
    }, [fetchDocuments, query.page]);


    if (isLoading && query.page === 1) {
        return (
            <ContentContainer>
                <CustomersInfoCard customPadding={'p-[30px]'}>
                    <div>Идет загрузка документов, это может занять некоторое время...</div>
                </CustomersInfoCard>
            </ContentContainer>
        );
    }

    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={'p-[30px]'}>
                <div className={'flex flex-col w-full'}>
                    <div className={'w-full flex  min-w-[600px] justify-end gap-x-3 py-3'}>
                        <div className={'flex items-center gap-x-2'}>
                            <BackArrowNavigate/>
                        </div>
                    </div>
                    <div className={'flex flex-wrap gap-5 w-full h-full justify-start pl-16'}>
                        {documents.map((doc: IMedia) => (
                            <Document media={doc} key={doc.id}/>
                        ))}
                    </div>
                    {isLoading && <div>Загрузка...</div>}
                </div>
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default CustomerDocumentsPage;