import React, {FC, ReactNode} from 'react';

interface CounterProps {
    count: number;
    icon: ReactNode;
}

const Counter: FC<CounterProps> = ({count, icon}) => {
    return (
        <div>
            <div className={'flex gap-x-2 items-center justify-center p-1.5 rounded-md min-w-[60px] bg-[#191919] text-[#d7d7d7]'}>
                <p>{count}</p>
                {icon}
            </div>
        </div>
    );
};

export default Counter;