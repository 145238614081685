import React, {useEffect, useState, useCallback} from 'react';
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import ContentContainer from "../../../components/layouts/ContentContainer";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {ICustomer} from "../../../common/types/customer";
import api from "../../../api/baseApi";
import {IMedia, MediaType} from "../../../common/types/media";
import {setSelectedImage} from "../../../store/slices/selectedImage-slice";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import {MediaQuery} from "../../../common/types/plagination";
import Image from "../../../components/media-components/Image";

const CustomerPhotosPage = () => {
    const [images, setImages] = useState<IMedia[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const customer = useAppSelector(state => state.customer.currentCustomer) as ICustomer;
    const id = customer.tgId;
    const dispatch = useAppDispatch();

    const [query, setQuery] = useState<MediaQuery>({
        page: 1,
        limit: 100,
        skip: 0,
        mediaType: MediaType.PHOTO
    });

    const fetchImages = useCallback(async (page: number) => {
        try {
            const url = `/customers/media/${id}?mediaType=${query.mediaType}&page=${page}&limit=${query.limit}`;
            const result = await api(url, {
                method: 'GET',
                headers: {
                    credentials: "include",
                },
            });
            const newImages = result as IMedia[];
            setImages(prevImages => [...prevImages, ...newImages]);
        } catch (error) {
            console.log("Error fetching images", error);
        } finally {
            setIsLoading(false);
        }
    }, [id, query.limit, query.mediaType]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 1) {
            setQuery(prev => ({...prev, page: prev.page + 1}));
        }
    }, [isLoading]);

    useEffect(() => {
        fetchImages(query.page);
    }, [fetchImages, query.page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (isLoading && query.page === 1) {
        return (
            <ContentContainer>
                <CustomersInfoCard customPadding={'p-[30px]'}>
                    <div>Идет загрузка фотографий, это может занять некоторое время...</div>
                </CustomersInfoCard>
            </ContentContainer>
        );
    }

    return (
        <ContentContainer>
            <CustomersInfoCard customPadding={'p-[30px]'}>
                <div className={'flex flex-col'}>
                    <div className={'w-full flex  min-w-[600px] justify-end gap-x-3 py-3'}>
                        <div className={'flex items-center gap-x-2'}>
                            <BackArrowNavigate/>
                        </div>
                    </div>
                    <div className={'flex flex-wrap gap-3 w-full h-full justify-start pl-16'}>
                        {images.map((image: IMedia) => (
                            <Image media={image} key={image.id}/>
                        ))}
                    </div>
                    {isLoading && <div>Загрузка...</div>}
                </div>
            </CustomersInfoCard>
        </ContentContainer>
    );
};

export default CustomerPhotosPage;
