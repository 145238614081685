import React, { FC, useState } from "react";
import { FaUser } from "react-icons/fa";

interface UserAvatarProps {
  url: string;
  customSize?: string;
  customIconSize?: number;
}

const Avatar: FC<UserAvatarProps> = ({ url, customIconSize, customSize }) => {
  const [isError, setIsError] = useState(false);
  if (url && !isError) {
    return (
      <img
        onError={() => setIsError(true)}
        className={`rounded-full ${
          customSize ? customSize : "w-[45px] h-[45px]"
        }`}
        src={url}
        alt={"User Avatar"}
      />
    );
  } else {
    return (
      <div
        className={`rounded-full ${
          customSize ? customSize : "w-[45px] h-[45px]"
        } text-gray-600 bg-gray-300 flex items-center justify-center`}
      >
        <FaUser size={customIconSize ? customIconSize : 30} />
      </div>
    );
  }
};

export default Avatar;
