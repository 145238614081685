import {ITelegramUser} from "./telegram-user";
import {IDialog} from "./dialog";

export interface IMedia {
    customerId: string;
    id?: string;
    tgId: string;
    messageId: string;
    dialogTgId: string;
    mediaType: string;
    url?: string;
    media?: string;
}

export interface IMediaData extends IMedia {
    sender: ITelegramUser;
    dialog: IDialog;
}


export enum MediaType {
    DOCUMENT = "document",
    PHOTO = "photo",
    VIDEO = "video",
    AUDIO = "audio",
}