import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  classes?: string;
}
export default function Button({ children, classes, ...props }: ButtonProps) {
  return (
    <button
      className={` last:w-full h-14 rounded-xl p-2.5 text-[rgb(51,144,236)]  outline-none bg-transparent hover:bg-[rgb(74,149,214,0.08)] transition-colors duration-300 ${classes}`}
      {...props}
    >
      {children}
    </button>
  );
}
