import {Tokens} from "../types/token";

export const setTokens = (tokens: Tokens) => {
    localStorage.setItem('tokens', JSON.stringify(tokens));
}


export const removeTokens = () => {
    localStorage.removeItem('tokens');
}

export const getTokens = (): Tokens | null => {
    const tokens = localStorage.getItem('tokens');
    return tokens ? JSON.parse(tokens) : null;
}