import React, {useState} from 'react';
import NavigationButton from "../buttons/NavigationButton";
import {FaUsers} from "react-icons/fa";
import { RiLinksFill } from "react-icons/ri";

import ContentContainer from "./ContentContainer";
import {MdExitToApp} from "react-icons/md";

import {useDispatch} from "react-redux";
import {logout} from "../../store/slices/auth-slice";

const AdminNavigation = () => {
    const dispatch = useDispatch();

    return (
        <ContentContainer>
            <nav className={'flex w-full justify-between py-5'}>
                <div className={'flex gap-x-6'}>
                    <NavigationButton
                        text={'Пользователи'}
                        href={'/customers'}
                        icon={<FaUsers size={20}/>}
                    />
                    <NavigationButton
                        text={'Линки'}
                        href={'/customers-links'}
                        icon={<RiLinksFill size={20}/>}
                    />
                </div>
                <NavigationButton
                    text={'Выход'}
                    href={'/admin/sign-in'}
                    icon={<MdExitToApp size={20}/>}
                    cb={() => {
                        dispatch(logout())
                    }}
                />
            </nav>
        </ContentContainer>

    );
};

export default AdminNavigation;