import React, { useEffect, useState, useCallback } from "react";
import { IMedia, MediaType } from "../../../common/types/media";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ICustomer } from "../../../common/types/customer";
import api from "../../../api/baseApi";
import ContentContainer from "../../../components/layouts/ContentContainer";
import CustomersInfoCard from "../../../components/cards/CustomersInfoCard";
import BackArrowNavigate from "../../../components/buttons/BackArrowNavigate";
import UrlVideo from "../../../components/media-components/UrlVideo";
import { MediaQuery } from "../../../common/types/plagination";
import { Video } from "../../../components/media-components/Video";

const CustomerVideosPage = () => {
  const [videos, setVideos] = useState<IMedia[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer
  ) as ICustomer;
  const id = customer.tgId;

  const [query, setQuery] = useState<MediaQuery>({
    page: 1,
    limit: 100,
    skip: 0,
    mediaType: MediaType.VIDEO,
  });

  const fetchVideos = useCallback(
    async (page: number) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("mediaType", "video");
        params.append("page", page.toString());
        params.append("limit", query.limit.toString());
        const url = `/customers/media/${id}?${params.toString()}`;

        const result = await api(url, {
          method: "GET",
          headers: {
            credentials: "include",
          },
        });
        const newVideos = result as IMedia[];
        setVideos((prevVideos) => [...prevVideos, ...newVideos]);
      } catch (error) {
        console.log("Error fetching videos", error);
      } finally {
        setIsLoading(false);
      }
    },
    [id, query.limit]
  );

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 1
    ) {
      setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [isLoading]);

  useEffect(() => {
    fetchVideos(query.page);
  }, [fetchVideos, query.page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading && query.page === 1) {
    return (
      <ContentContainer>
        <CustomersInfoCard customPadding={"p-[30px]"}>
          <div>Идет загрузка видео, это может занять некоторое время...</div>
        </CustomersInfoCard>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <CustomersInfoCard customPadding={"p-[30px]"}>
        <div className={"min-h-screen flex flex-col"}>
          <div className={"w-full flex justify-end gap-x-3 py-3"}>
            <div className={"flex items-center gap-x-2"}>
              <BackArrowNavigate />
            </div>
          </div>
          <div
            className={
              "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full h-full justify-start"
            }
          >
            {videos.map((video, index) => (
              <Video media={video} key={index} />
            ))}
          </div>
          {isLoading && <div>Загрузка...</div>}
        </div>
      </CustomersInfoCard>
    </ContentContainer>
  );
};

export default CustomerVideosPage;
