import React, {FC, ReactNode} from 'react';
import {NavLink, useLocation} from "react-router-dom";


interface NavigationButtonProps {
    text: string;
    href: string;
    icon: ReactNode;
    cb?: () => void;
}

const NavigationButton: FC<NavigationButtonProps> = ({text, href, icon, cb}) => {
    return (
        <NavLink
            onClick={cb}
            className={'flex gap-x-2 items-center text-white hover:text-gray-300 p-2 transition-colors bg-gray-700 rounded-lg hover:bg-gray-600'}
            to={href}>
            {icon}
            {text}
        </NavLink>
    );
};

export default NavigationButton;