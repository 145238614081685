import React, {FC} from 'react';
import {IMedia} from "../../common/types/media";
import {UrlImage} from "./UrlImage";
import {BufferImage} from "./BufferImage";


interface ImageProps {
    media: IMedia;
}

const Image: FC<ImageProps> = ({media}) => {
    if ('url' in media) {
        return <UrlImage media={media}/>
    } else {
        return <BufferImage media={media}/>
    }
};

export default Image;