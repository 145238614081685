import React, {FC, ReactNode, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";

interface CustomerNavButtonProps {
    icon: ReactNode;
    text: string;
    href: string;
    cb?: () => {};
}

const CustomerNavButton: FC<CustomerNavButtonProps> = ({icon, href, text, cb}) => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(href === location.pathname);

    useEffect(() => {
        setIsActive(href === location.pathname);
    }, [location.pathname]);

    return (
        <Link
            onClick={cb}
            className={`font-bold text-lg flex items-center gap-x-2 w-[135px] p-2 rounded-md ${isActive ? 'cursor-default bg-[#3d3e3f]' : 'hover:bg-[#252627] hover:text-[#d7d7d7]'} cursor-pointer transition-all `}
            to={href}>
            {icon}
            <span>{text}</span>
        </Link>
    );
};

export default CustomerNavButton;