import React, {FC} from 'react';

interface BufferAudioProps {
    audioData: string;
    classes?: string;
}

const BufferAudio: FC<BufferAudioProps> = ({audioData, classes}) => {

    return (
        <audio controls className={classes}>
            <source src={`data:audio/mp3;base64,${audioData}`} type="audio/mp3"/>
            Your browser does not support the audio element.
        </audio>
    );
};

export default BufferAudio;