import React, { FC } from "react";

interface BufferVideoComponentProps {
  url: string;
  className?: string;
}

const UrlVideo: FC<BufferVideoComponentProps> = ({ url, className }) => {
  return (
    <video
      controls
      className={` w-full h-full max-w-[250px] max-h-[250px] pb-2 rounded-md  ${className}`}
    >
      <source src={url} type="video/mp4" />
    </video>
  );
};

export default UrlVideo;
