import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {RootState} from "../../store/store";
import { Navigate, Outlet } from "react-router-dom";
import { logout } from "../../store/slices/auth-slice";
import AdminNavigation from "../../components/layouts/AdminNavigation";
import AdminBackground from "../../components/layouts/AdminBackground";
import SelectedImage from "../../components/layouts/selected-image/SelectedImage";
import { getTokens } from "../../common/util/tokens";

export default function AdminRoute() {
  const authState = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  const tokens = getTokens();

  const isAuth = !!tokens && authState.isAuthenticated;

  useEffect(() => {
    const tokens = getTokens();
    if (!tokens) {
      dispatch(logout());
    }
  }, [isAuth, dispatch]);
  return isAuth ? (
    <>
      <SelectedImage />
      <AdminBackground>
        <AdminNavigation />
        <Outlet />
      </AdminBackground>
    </>
  ) : (
    <Navigate to="/admin/sign-in" />
  );
}
