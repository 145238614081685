import React, {FC} from 'react';
import {DialogType} from "../../../common/types/dialog";
import {FaUser} from "react-icons/fa6";
import { HiUsers } from "react-icons/hi2";
import { IoMegaphoneSharp } from "react-icons/io5";


interface DialogTypeIconProps {
    dialogType: DialogType;
}

const DialogTypeIcon: FC<DialogTypeIconProps> = ({dialogType}) => {
    if (dialogType === 'user') {
        return <FaUser size={18}/>
    } else if (dialogType === 'group') {
        return <HiUsers size={18}/>
    } else {
        return <IoMegaphoneSharp size={18}/>
    }
};

export default DialogTypeIcon;