import React, {FC, ReactNode} from 'react';

interface IMessageContainerProps {
    customerId: string;
    senderId: string;
    children: ReactNode;
}

const MessageContainer: FC<IMessageContainerProps> = ({customerId, children, senderId}) => {
    const isCustomerSender = customerId === senderId;
    return (
        <div className={`flex w-full ${isCustomerSender ? 'justify-end': 'justify-start'}`}>
            {children}
        </div>
    );
};

export default MessageContainer;