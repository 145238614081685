import React, {FC} from 'react';
import { IoIosDocument } from "react-icons/io";

interface DownloadDocumentProps {
    url: string;
    mimeType: string;
}

const UrlDownloadDocument: FC<DownloadDocumentProps> = ({url, mimeType}) => {
    const name = mimeType.split('/')[1];
    return (
        <a
            href={url} download={`${name}`}
            className={'flex w-[150px] overflow-hidden gap-y-3 select-none cursor-pointer  h-[130px] text-white justify-start items-center flex-col p-3 rounded-md transition-colors hover:bg-[#3d3e3f]'}>
            <IoIosDocument size={50}/>
            <span className={'line-clamp-1'}>{name}</span>
        </a>
    );
};

export default UrlDownloadDocument;