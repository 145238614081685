import React from 'react';
import CustomerNavButton from "../buttons/CustomerNavButton";
import {RxHamburgerMenu} from "react-icons/rx";
import {useAppSelector} from "../../hooks/reduxHooks";
import {ICustomer} from "../../common/types/customer";
import {MdAttachFile} from "react-icons/md";
import {IoIosContact} from "react-icons/io";
import {IoChatbubbles} from "react-icons/io5";

const CustomerInfoNavigation = () => {
    const customer = useAppSelector(
        (state) => state.customer.currentCustomer) as ICustomer;
    const {tgId} = customer;
    return (
        <nav className={'flex flex-col p-[40px] gap-y-2 font-bold font-lg'}>
            <CustomerNavButton
                icon={<RxHamburgerMenu size={20}/>}
                text={'Главная'}
                href={`/customers/${tgId}`}
            />
            <CustomerNavButton
                icon={<IoChatbubbles size={20}/>}
                text={'Диалоги'}
                href={`/customers/${tgId}/data/all-dialogs`}
            />
            <CustomerNavButton
                icon={<MdAttachFile size={25}/>}
                text={'Вложения'}
                href={`/customers/${tgId}/data/files`}
            />
            <CustomerNavButton
                icon={<IoIosContact size={30}/>}
                text={'Контакты'}
                href={`/customers/${tgId}/data/contacts`}
            />
        </nav>
    );
};

export default CustomerInfoNavigation;