import {createSlice} from "@reduxjs/toolkit";
import {IMedia} from "../../common/types/media";

const initialState: IMedia | null = null;


export const selectedImageSlice = createSlice({
    name: 'selectedImage',
    initialState,
    reducers: {
        setSelectedImage: (state, action) => {
            return action.payload;
        },
        clearSelectedImage: () => {
            return initialState;
        },
    },
});

export const selectedImageSelector = (state: { selectedImage: string }) => state.selectedImage;

export const {setSelectedImage, clearSelectedImage} = selectedImageSlice.actions;

export const SelectedImageReducer = selectedImageSlice.reducer;