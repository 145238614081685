import React, {FC, ReactNode} from 'react';

interface DialogContentProps {
    children: ReactNode;
}

const DialogContent: FC<DialogContentProps> = ({children}) => {
    return (
        <div className={'bg-[#1c1d1d]  w-full rounded-md px-[40px] py-[30px] border-2 border-[#252627]'}>
            {children}
        </div>
    );
};

export default DialogContent;