import {FC} from "react";
import {IMedia} from "../../common/types/media";
import UrlVideo from "./UrlVideo";
import BufferVideo from "./BufferVideo";

interface VideoProps {
    media: IMedia;
}

export const Video: FC<VideoProps> = ({media}) => {
    if ('url' in media) {
        return (
            <UrlVideo url={media.url as string}/>
        )
    } else {
        return (
            <BufferVideo src={media.media as string} videoType={media.mediaType}/>
        )
    }
}