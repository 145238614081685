import React, {FC} from 'react';
import {useAppDispatch} from "../../../../hooks/reduxHooks";
import {setSelectedImage} from "../../../../store/slices/selectedImage-slice";
import {IMedia} from "../../../../common/types/media";

interface IMessageImageProps {
    media: IMedia;
}

const MessageImage: FC<IMessageImageProps> = ({media}) => {
    const dispatch = useAppDispatch();

    if ('url' in media) {
        return (
            <img
                onClick={() => dispatch(setSelectedImage(media))}
                className={'w-full h-[200px] rounded-md cursor-pointer'}
                src={media.url}
                alt={'message-image'}
            />
        );
    } else {
        return (
            <img
                onClick={() => dispatch(setSelectedImage(`data:image/jpeg;base64,${media}`))}
                src={`data:image/jpeg;base64,${media.media}`}
                alt={'image'}
                className={'w-52 h-52 cursor-pointer p-2 rounded-md hover:bg-[#3d3e3f] transition-colors'}/>
        )
    }

};

export default MessageImage;