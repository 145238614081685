const ISO_FLAGS_OFFSET = 127397;

export function isoToEmoji(iso: string) {
  if (iso === "FT") {
    return "\uD83C\uDFF4\u200D\u2620\uFE0F";
  }
  const code = iso.toUpperCase();
  return code
    .toUpperCase()
    .split("")
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + ISO_FLAGS_OFFSET))
    .join("");
}

export const isValidImageUrl = (url: string) => {
  const imagePattern = /\.(jpeg|jpg|gif|png|webp)$/i;
  try {
    const parsedUrl = new URL(url);
    return imagePattern.test(parsedUrl.pathname);
  } catch (e) {
    return false;
  }
};